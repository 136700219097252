import React, { useRef } from "react";
import { css } from "@emotion/react";
import { get, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { rangexy } from "main/javascripts/utils/ArrayUtil";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { FormNamespaces } from "../../constants/FormConstants";

export interface IProps {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
}

export const FlightTravelerBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { labelColorStyleKey, borderColorStyleKey } = props;
  const { t } = useTranslation(["label"]);

  const MAX_ADULT_NUM = 8;
  const MAX_CHILD_NUM = 8;
  const MAX_CHILD_AGE = 11;

  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const values = useWatch();
  const { fields, append, remove } = useFieldArray({
    name: "traveler.ages_of_children",
  });

  const valuesRef = useRef(values);
  valuesRef.current = values;

  const renderChildAge: (index: number) => JSX.Element = (index: number) => {
    const fieldName = `traveler.ages_of_children.${index}.age`;
    const optionList: JSX.Element[] = [
      <option key="none" value="" disabled={true}>
        年齢
      </option>,
    ];

    // const { ageInputStyle, ageInputMultiStyle, labelAgeStyle } = styles;
    //
    // const propStyle: any =
    //   Number(values.flightTraveler_num || 1) === 1
    //     ? ageInputStyle
    //     : ageInputMultiStyle;

    // tslint:disable-next-line:prefer-array-literal
    Array.from(Array(MAX_CHILD_AGE + 1).keys()).map((childAge: number) => {
      optionList.push(
        <option key={childAge} value={childAge}>{`${childAge} 歳`}</option>
      );
    });

    return (
      <SelectBlock
        namespace={FormNamespaces.flight}
        key={index}
        // styles={[
        //   FlightTravelerBlockStyle.flightTravelerInputBlockSmall,
        //   propStyle,
        // ]}
        label={{
          namespace: "common",
          label: t("label:common.flightAgesOfChildren", {
            index: Number(index) + 1,
          }),
          colorStyleKey: labelColorStyleKey,
          // styles: [FlightTravelerBlockStyle.childLabel, labelAgeStyle],
        }}
        select={{
          name: fieldName,
          children: optionList,
        }}
        error={get(errors, fieldName)}
        borderColorStyleKey={borderColorStyleKey}
      />
    );
  };

  const renderNumberOfChildren: () => JSX.Element = () => {
    const fieldName = "traveler.number_of_children";
    const optionList: JSX.Element[] = [];

    // tslint:disable-next-line:prefer-array-literal
    Array.from(
      Array(MAX_CHILD_NUM + 1 - (values.traveler.number_of_adult || 0)).keys()
    ).map((childNum: number) => {
      optionList.push(
        <option key={childNum} value={childNum}>{`${childNum} 人`}</option>
      );
    });
    return (
      <div css={flightTravelerInputBlockStyle}>
        <SelectBlock
          namespace={FormNamespaces.flight}
          label={{
            namespace: "common",
            label: t("label:common.flightNumberOfChildren"),
            colorStyleKey: labelColorStyleKey,
          }}
          select={{
            name: fieldName,
            onChange: onChangeNumberOfChildren(),
            children: optionList,
          }}
          error={get(errors, fieldName)}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
    );
  };

  const renderNumberOfAdult: () => JSX.Element = () => {
    const fieldName = "traveler.number_of_adult";
    const optionList: JSX.Element[] = [];

    // tslint:disable-next-line:prefer-array-literal
    Array.from(
      Array(MAX_ADULT_NUM - (values.traveler.number_of_children || 0)).keys()
    ).map((adultNum: number) => {
      optionList.push(
        <option key={adultNum} value={adultNum + 1}>{`${
          adultNum + 1
        } 人`}</option>
      );
    });
    return (
      <div css={flightTravelerInputBlockStyle}>
        <SelectBlock
          namespace={FormNamespaces.flight}
          label={{
            namespace: "common",
            label: t("label:common.flightNumberOfAdult"),
            colorStyleKey: labelColorStyleKey,
          }}
          select={{
            name: fieldName,
            children: optionList,
          }}
          error={get(errors, fieldName)}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
    );
  };

  // useFieldArrayではオブジェクト単位で追加する必要があり
  // indexで終わるkeyに対して値を追加できないため
  // .ageで管理し、リクエスト時に整形する
  const onChangeNumberOfChildren = (): any => {
    return (e: any): void => {
      const oldNumberOfChildren: number = parseInt(
        valuesRef.current.traveler.number_of_children,
        10
      );
      const numberOfChildren: number = parseInt(e.target.value, 10);
      setValue("traveler.number_of_children", numberOfChildren);
      if (oldNumberOfChildren > numberOfChildren) {
        remove(rangexy(numberOfChildren, oldNumberOfChildren));
      } else if (oldNumberOfChildren < numberOfChildren) {
        rangexy(oldNumberOfChildren, numberOfChildren).map((_: number) => {
          append({ age: "" });
        });
      }
    };
  };

  return (
    <div css={flightTravelerBlockStyle}>
      <div css={flightTravelerInputGroupBlockStyle}>
        <div css={flightTravelerInputGroupBlockMediumStyle}>
          {renderNumberOfAdult()}
          {renderNumberOfChildren()}
        </div>
        <div css={flightTravelerInputGroupBlockStyle}>
          {fields.map((field, index) => (
            <div key={field.id} css={flightTravelerInputBlockSmallStyle}>
              {renderChildAge(index)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const flightTravelerBlockStyle = css`
  display: flex;
  flex-wrap: wrap;
  padding: ${space.atom} 0;
`;
const flightTravelerInputGroupBlockStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const flightTravelerInputBlockStyle = css`
  width: 50%;
`;
const flightTravelerInputGroupBlockMediumStyle = css`
  width: 66.666%;
  display: flex;
`;
const flightTravelerInputBlockSmallStyle = css`
  width: 33.333%;
  ${moreThanBreakpoint("tablet")} {
    width: 25%;
  }
`;
